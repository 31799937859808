<template>
    <div>
        <v-dialog v-model="odooFailure" persistent max-width="320">
            <v-card>
                <v-card-title style="word-break:normal">
                    <p> Sorry, E-pharmacy is currently not available.</p>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="btnClass" depressed @click="redirectToHome">
                        Redirect to Home
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div v-if="dataLoading">
        </div>
        <div v-else>
            <img class="gif-class" src="https://s3iconimages.mymedicine.com.mm/buildingProfile.gif" alt="Loading">
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
	name: 'ePharmacyHomePage',
    data() {
		return {
            token: null,
            OdooAccessToken: null,
            iframeUrl: null,
            dataLoading: false,
            odooFailure: false,
            source: null,
            platform: null,
        }
    },
    mounted() {
        try {
            this.token = this.$cookies.get('customerToken');
            let params = this.$route.params
            this.platform = localStorage.getItem('platform');
            this.source = params['source']
            let OdooAccessTokenRequest = {
                token: this.token,
                typeOfUser: 'CUSTOMER'
            }
            axios.post(process.env.VUE_APP_BACKEND_URL + '/OdooAccessToken', OdooAccessTokenRequest)
            .then((OdooAccessTokenResponse) => {
                this.OdooAccessToken = OdooAccessTokenResponse.data.data;
                this.dataLoading = true;
                if (this.platform !== null && this.platform === 'AYA') {
                    if(this.source === 'HOME') {
                        let accessURL = process.env.VUE_APP_AYA_ODOO_ACCESS_URL
                        this.iframeUrl = `${accessURL}${this.OdooAccessToken}`;
                        window.location.href = this.iframeUrl;
                    } else {
                        this.iframeUrl = process.env.VUE_APP_EPHARMACY_AYA_REDIRECT_URL
                        window.location.href = this.iframeUrl;
                    }
                } else {
                    if(this.source === 'HOME') {
                        let accessURL = process.env.VUE_APP_ODOO_ACCESS_URL
                        console.log('accessURL',accessURL)
                        this.iframeUrl = `${accessURL}${this.OdooAccessToken}`;
                        window.location.href = this.iframeUrl;
                    } else {
                        this.iframeUrl = process.env.VUE_APP_EPHARMACY_REDIRECT_URL
                        window.location.href = this.iframeUrl;
                    }
                }
            }).catch((OdooAccessTokenError) => {
                this.dataLoading = true;
                this.odooFailure = true;
            })
        } catch (odooError) {
            this.dataLoading = true;
            this.odooFailure = true;
        }
    },
    methods: {
        redirectToHome() {
            this.$router.push({
                name: 'CustomerHomeScreen'
            })
        }
    }
}
</script>
<style scoped>
.btnClass {
	background-color: #48ACEF !important;
	color: white;
    text-transform: none;
    font-weight: 600;
}
.gif-class {
    margin-top: 35%;

    @media (max-width: 600px) {
        width: 60dvw;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 30dvw;
    }

    @media (min-width: 1265px) {
        width: 30dvw;
    }
}
</style>